import React, { Fragment, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import RbacWrapper from 'components/RbacWrapper'
import ReactTooltip from 'react-tooltip'
import featureAccessMap from 'global/rbac.json'
import { includePlans } from 'utils/constants'

const SubItems = ({ item }) => {
  return (
    <div className='nav-link align-items-center'>
      {item.icon}
      <span className='navbar-vertical-aside-mini-mode-hidden-elements text-truncate'>
        {item.title}
      </span>
    </div>
  )
}

const SubMenuItems = ({ item }) => {
  return (
    <div className='nav-link' data-tip data-for={item.title}>
      <ReactTooltip id={item.title} place='right' effect='solid'>
        You don't have permission to access {item.title}. Please contact your
        administrator.
      </ReactTooltip>
      <div className='text-truncate text-muted'>{item.title}</div>
    </div>
  )
}

const SubMenu = ({ item, theme, handleLogout, userData, orgData }) => {
  const location = useLocation()

  const [subnav, setSubnav] = useState(false)
  const showSubnav = () => setSubnav(!subnav)

  const [subsubnav, setSubsubnav] = useState(false)
  const showSubsubnav = () => setSubsubnav(!subsubnav)

  useEffect(() => {
    const route = location.pathname
    const maxLevel = 2
    const levels = route.split('/')
    const accessLevels = levels.slice(0, maxLevel + 1)
    const finalPathname = accessLevels.join('/')

    if (item.path && location.pathname) {
      setSubnav(false)
    } else if (item && item.subNav) {
      let shouldOpen = false

      item.subNav.forEach((element) => {
        if (element.path && element.path === finalPathname) {
          shouldOpen = true
        }
        if (element && element.subNav) {
          element.subNav.forEach((elem) => {
            if (elem.path && elem.path === finalPathname) {
              shouldOpen = true
              setSubsubnav(true)
            }
          })
        }
      })
      setSubnav(shouldOpen)
    } else {
      setSubnav(false)
      setSubsubnav(false)
    }
  }, [location])

  const beforeNavigation = () => {
    // run script before navigation // url changes
  }

  // Check if none of the roles in 'stream' exist in 'userData.roles' and the current plan is not an AppSumo plan
  const showLiveStream = (userData?.roles?.some(role => ['super-admin', 'live-video-dev'].includes(role))) || includePlans.appSumoPlans.includes(orgData?.plan_data?.video?.plan)
  // console.log(userData)
  return (
    <>
      {item.title && (
        <div onClick={item.subNav && showSubnav} className='nav-item'>
          {item.path
            ? (
              <RbacWrapper path={item.path} fallback={<SubItems item={item} />}>
                <NavLink
                  to={item.path}
                  className='nav-link align-items-center'
                  style={({ isActive }) => ({
                    backgroundColor: isActive
                      ? theme === 'dark'
                        ? 'rgba(255, 255, 255, 0.075)'
                        : 'rgba(189, 197, 209, 0.2)'
                      : ''
                  })}
                  onClick={beforeNavigation}
                >
                  {item.icon}
                  <span className='navbar-vertical-aside-mini-mode-hidden-elements text-truncate'>
                    {item.title}
                  </span>
                </NavLink>
              </RbacWrapper>
              )
            : item.title === 'Live Streams' && !showLiveStream
              ? (
                <></>
                )
              : (
                <a
                  className={`nav-link ${item.isToggle !== 'false' ? 'dropdown-toggle' : ''}`}
                  aria-expanded={item.subNav && subnav ? 'true' : 'false'}
                  href={item.outpath}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item.icon}
                  <span className='navbar-vertical-aside-mini-mode-hidden-elements text-truncate'>
                    {item.title}
                  </span>
                </a>
                )}
        </div>
      )}
      {item.tagtitle && (
        <span className='dropdown-header mt-2'>{item.tagtitle}</span>
      )}
      {item.replacer && (
        <small className='nav-subtitle-replacer'>{item.replacer}</small>
      )}
      <div className='nav-collapse'>
        {subnav &&
          item.subNav.map((item, index) => {
            if (item.subNav) {
              return (
                <Fragment key={index}>
                  <div
                    className='nav-link dropdown-toggle'
                    onClick={showSubsubnav}
                    role='button'
                  >
                    {item.title}
                  </div>

                  {subsubnav && (
                    <div className='nav-collapse'>
                      {item.subNav.map((subSubMenu, index) => {
                        if (subSubMenu.outpath) {
                          return (
                            <RbacWrapper
                              key={index}
                              path={subSubMenu.outpath}
                              fallback={<SubMenuItems item={subSubMenu} />}
                            >
                              <div className='nav-item'>
                                <a
                                  className='nav-link'
                                  href={subSubMenu.outpath}
                                >
                                  <span className='text-truncate'>
                                    {subSubMenu.title}
                                  </span>
                                </a>
                              </div>
                            </RbacWrapper>
                          )
                        } else {
                          return (
                            <RbacWrapper
                              key={index}
                              path={subSubMenu.path}
                              fallback={<SubMenuItems item={subSubMenu} />}
                            >
                              <div className='nav-item'>
                                <NavLink
                                  className='nav-link'
                                  to={subSubMenu.path}
                                  onClick={beforeNavigation}
                                >
                                  <span className='text-truncate'>
                                    {subSubMenu.title}
                                  </span>
                                </NavLink>
                              </div>
                            </RbacWrapper>
                          )
                        }
                      })}
                    </div>
                  )}
                </Fragment>
              )
            } else if (item.path) {
              return (
                <RbacWrapper
                  key={index}
                  path={item.path}
                  fallback={<SubMenuItems item={item} />}
                >
                  <NavLink
                    to={item.path}
                    className='nav-link align-items-center'
                    style={({ isActive }) => ({
                      backgroundColor: isActive
                        ? theme === 'dark'
                          ? 'rgba(255, 255, 255, 0.075)'
                          : 'rgba(189, 197, 209, 0.2)'
                        : ''
                    })}
                    onClick={beforeNavigation}
                  >
                    <div className='text-truncate'>{item.title}</div>
                  </NavLink>
                </RbacWrapper>
              )
            } else if (item.onclick) {
              return (
                <a
                  onClick={handleLogout}
                  className='nav-link'
                  type='button'
                  key={index}
                >
                  <div className='text-truncate'>{item.title}</div>
                </a>
              )
            } else {
              return (
                <RbacWrapper
                  key={index}
                  path={item.outpath}
                  fallback={<SubMenuItems item={item} />}
                >
                  <a href={item.outpath} className='nav-link'>
                    <div className='text-truncate'>{item.title}</div>
                  </a>
                </RbacWrapper>
              )
            }
          })}
      </div>
    </>
  )
}

export default SubMenu
